import { Link } from "react-router-dom";
import { Head, Layout, ListEvent } from "../components";

export default function Home() {
  return (
    <>
      <Head
        title="Home"
        bodyClass="hp"
        description="Studio fotografico, ma anche luogo di incontri, conferenze, eventi e workshop creati per apprendere nuove tecniche legate all'arte e al mondo dell'artigianato, ma anche salotto dal quale passare a fare quattro chiacchiere e scambiarsi idee.Spazio Supernova - via Borgazzi 87/A - 20900 Monza"
        url="/"
      />
      <Layout>
        <div className="hero">
          <a
            href="https://spaziosupernova.bigcartel.com/"
            className="store"
            target="_blank"
            title="Siamo su BigCartel!"
          >
            <h3>Spazio Supernova è anche su</h3>
            <img
              src="assets/img/big_cartel_logo.png"
              alt="Spazio Supernova | Siamo su BigCartel!"
              style={{ maxHeight: "15rem" }}
            />
          </a>
        </div>
        <section className={`flez-home list cards`}>
          <div className="col">
            <h3>Workshop nei fine settimana</h3>
            {[
              {
                id: 1000,
                title: "Collage creativo - Naomi Vona - 06/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/collage-creativo",
                category: { name: "workshops" },
                image_link: "20241006_collage_creativo.png",
              },
              {
                id: 1001,
                title: "Stampa su tessuto - Bluma Studio - 27/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/stampa-su-tessuto",
                category: { name: "workshops" },
                image_link: "20241027_stampa_su_tessuto.png",
              },
              {
                id: 1002,
                title: "Rifugi - Martina Lucidi - 09/11/2024",
                link: "",
                category: { name: "workshops" },
                image_link: "20241109_rifugi.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Workshop serali</h3>
            {[
              {
                id: 1100,
                title: "Uncinetto - Alessandra FRAILSOLE - 01/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/uncinetto",
                category: { name: "workshops" },
                image_link: "20241001_uncinetto.png",
              },
              {
                id: 1101,
                title: "Corso di Maglia - V di Vipera - 03/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/corso-serale-di-maglia",
                category: { name: "workshops" },
                image_link: "20241003_maglia.png",
              },
              {
                id: 1102,
                title: "Ricamo Creativo - Simona di La Beba Embroidery",
                link: "",
                category: { name: "workshops" },
                image_link: "20241108_ricamo_creativo.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Workshop bambini</h3>
            {[
              {
                id: 1200,
                title: "Laboratorio Bimbi - Ludotecha Tokate & Onalim - 22/09/2024",
                link: "https://spaziosupernova.bigcartel.com/product/lab-bimbi-giochi-da-tavolo",
                category: { name: "workshops" },
                image_link: "20240922_lab_bambini.png",
              },
              {
                id: 1201,
                title:
                  "Laboratorio Bimbi - Dalle foglie al bosco d'autunno - Federico De Cicco e Chiaracleo - 12/10/2024",
                link: "",
                category: { name: "workshops" },
                image_link: "20241012_dalle_foglie_al_bosco.png",
              },
              {
                id: 1202,
                title: "Laboratorio Bimbi - Giochi da Cubisti - Art U Associazione - 19/10/2024",
                link: "",
                category: { name: "workshops" },
                image_link: "20241019_giochi_da_cubisti.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
        </section>
        <ListEvent mode="next" />
        <section className="hero">
          <img src="assets/img/sayhi.jpg" alt="Spazio Supernova" style={{ maxHeight: "40rem" }} />
        </section>
      </Layout>
    </>
  );
}
