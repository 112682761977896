import { NavLink } from "react-router-dom";
export default function Menu() {
  return (
    <>
      <nav>
        <ul>
          <li>
            <NavLink
              to={"/workshops"}
              title="Workshop"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Workshop</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/events"}
              title="Eventi"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Eventi</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/chi-siamo"}
              title="Chi siamo"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Chi siamo</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"/galleria"}
              // title="Galleria"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Galleria</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to={"/contatti"}
              title="Contatti"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Contatti</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"/search"}
              // title="Contatti"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Ricerca</span>
            </NavLink>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/spazio_supernova/"
              rel="noreferrer"
              target="_blank"
              aria-label="Supernova su Instagram"
            >
              <span className="icon-instagram" aria-hidden="true"></span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
