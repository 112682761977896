import { Routes, Route } from "react-router-dom";
import {
  Home,
  About,
  Workshop,
  Event,
  NoMatch,
  Gallery,
  Contact,
  Admin,
  SearchPage,
} from "./pages";
import Detail from "./pages/Detail";
import Artist from "./pages/Artist";

export const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/chi-siamo" element={<About />} />
      <Route path="/workshops" element={<Workshop />} />
      <Route path="/workshops/:content_id" element={<Detail />} />

      <Route path="/events" element={<Event />} />
      <Route path="/events/:content_id" element={<Detail />} />

      {/* <Route path="/artists/:id/:full_name" element={<Artist />} /> */}
      {/* <Route path="/galleria" element={<Gallery />} /> */}
      <Route path="/contatti" element={<Contact />} />
      {/* <Route path="/admino" element={<Admin />} /> */}
      {/* <Route path="/search" element={<SearchPage />} /> */}
      {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
